import React, {FC, Fragment, useMemo} from 'react';
import {
  OuterCircle,
  InnerCircle,
  DiagramContainer,
  Ray,
  SvgContainer,
  CircleContainer,
  TextCircle,
  HeadingText,
  BluePoint,
  CardBox,
  CardsList,
  BenefitsContainer,
} from './Benefits.styled';
import {ReactComponent as BenefitsIcon1} from 'assets/benefits_icon1.svg';
import {ReactComponent as BenefitsIcon3} from 'assets/benefits_icon3.svg';
import {ReactComponent as BenefitsIcon4} from 'assets/benefits_icon4.svg';

import {calculateCoordinates} from 'pages/home/helpers/calculateCoordinates';
import {BenefitCard} from './BenefitCard';
import {SvgGradient} from './SvgGradient';
import {useTheme} from 'styled-components';

const cardsData = [
  {
    icon: BenefitsIcon4,
    title: 'Trusted Experts:',
    text: 'Dedicated licensed M.D.s and D.O.s to guide your health journey',
  },
  {
    icon: BenefitsIcon1,
    title: 'Synergistic Care:',
    text: 'Thoughtful integration of medication management and therapy for optimal well-being',
  },
  {
    icon: BenefitsIcon3,
    title: 'Customized Treatment Solutions:',
    text: 'Care designed for your needs, with the attention you deserve.',
  },
];

const Benefits: FC = () => {
  const {isDesktop} = useTheme();

  const INNER_CIRCLE_RADIUS = isDesktop ? 260 : 150;
  const OUTER_CIRCLE_RADIUS = isDesktop ? 400 : 150;

  const RAY_ANGLES = useMemo(() => [-82, -45, -10], []);

  const rayCoords = useMemo(
    () =>
      RAY_ANGLES.map(angle =>
        calculateCoordinates(OUTER_CIRCLE_RADIUS, INNER_CIRCLE_RADIUS, angle),
      ),
    [OUTER_CIRCLE_RADIUS, INNER_CIRCLE_RADIUS, RAY_ANGLES],
  );

  return (
    <BenefitsContainer>
      <DiagramContainer>
        <OuterCircle $radius={OUTER_CIRCLE_RADIUS}>
          {isDesktop && (
            <>
              <SvgContainer>
                <SvgGradient />
                {rayCoords.map(({inner, outer}, index) => (
                  <Ray x1={inner.x} y1={inner.y} x2={outer.x} y2={outer.y} key={index} />
                ))}
              </SvgContainer>
              {rayCoords.map(({inner, outer}, index) => {
                const {icon, text, title} = cardsData[index];
                return (
                  <Fragment key={index}>
                    <BluePoint $posX={inner.x} $posY={inner.y} />
                    <CardBox $position="absolute" $posX={outer.x} $posY={outer.y}>
                      <BenefitCard svgIcon={icon} text={text} title={title} />
                    </CardBox>
                  </Fragment>
                );
              })}
            </>
          )}
          <InnerCircle $radius={INNER_CIRCLE_RADIUS}>
            <CircleContainer>
              <TextCircle>
                <HeadingText>
                  <span className="highlighted">Why Choose</span>
                  <br />
                  Our Best Psychiatrist Network?
                </HeadingText>
              </TextCircle>
            </CircleContainer>
          </InnerCircle>
        </OuterCircle>
      </DiagramContainer>
      {!isDesktop && (
        <CardsList>
          {rayCoords.map((_, index) => {
            const {icon, text, title} = cardsData[index];
            return (
              <React.Fragment key={index}>
                <CardBox $position="static">
                  <BenefitCard svgIcon={icon} text={text} title={title} />
                </CardBox>
              </React.Fragment>
            );
          })}
        </CardsList>
      )}
    </BenefitsContainer>
  );
};

export {Benefits};
